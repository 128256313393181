var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "pa-3" },
    [
      _c(
        "v-row",
        [
          _c("v-col", [_c("h4", { staticClass: "bold" }, [_vm._v("Devedor")])]),
          _c(
            "v-col",
            { staticClass: "d-flex justify-end" },
            [
              _c("select-actions", {
                attrs: {
                  actions: [
                    { name: "Alterar contatos", method: "editContacts" },
                    {
                      name: "Alterar Endereço",
                      method: "editAddress",
                      disabled:
                        _vm.computedPermitirCorrecao &&
                        _vm.titulo.ds_revisao !== "AP"
                    },
                    { name: _vm.labelMailing, method: "setMailing" }
                  ]
                },
                on: { executeAction: _vm.callMethod }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pb-3", attrs: { cols: "4" } },
            [
              _vm.checarNomeDevedorCRA(_vm.titulo)
                ? _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      dense: "",
                      label: "Devedor Ajustado",
                      required: "",
                      readonly: "",
                      "hide-details": "auto"
                    },
                    model: {
                      value: _vm.titulo.nm_devedor_cra,
                      callback: function($$v) {
                        _vm.$set(_vm.titulo, "nm_devedor_cra", $$v)
                      },
                      expression: "titulo.nm_devedor_cra"
                    }
                  })
                : _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      dense: "",
                      label: "Devedor",
                      required: "",
                      readonly: "",
                      "hide-details": "auto"
                    },
                    model: {
                      value: _vm.titulo.nm_devedor,
                      callback: function($$v) {
                        _vm.$set(_vm.titulo, "nm_devedor", $$v)
                      },
                      expression: "titulo.nm_devedor"
                    }
                  })
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pb-3", attrs: { cols: "2" } },
            [
              _c("v-text-field", {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: ["XX.XXX.XXX/XXXX-##", "###.###.###-##"],
                    expression: "['XX.XXX.XXX/XXXX-##', '###.###.###-##']"
                  }
                ],
                attrs: {
                  outlined: "",
                  dense: "",
                  value: _vm.titulo.nu_identificacao_devedor,
                  label: "CNPJ/CPF",
                  readonly: "",
                  "hide-details": "auto"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pb-3" },
            [
              _vm.checarEnderecoDevedorCRA(_vm.titulo)
                ? _c("v-text-field", {
                    staticClass: "parcelas",
                    attrs: {
                      outlined: "",
                      dense: "",
                      label: "Endereço Ajustado",
                      required: "",
                      readonly: "",
                      "hide-details": "auto"
                    },
                    model: {
                      value: _vm.titulo.ds_endereco_devedor_cra,
                      callback: function($$v) {
                        _vm.$set(_vm.titulo, "ds_endereco_devedor_cra", $$v)
                      },
                      expression: "titulo.ds_endereco_devedor_cra"
                    }
                  })
                : _c("v-text-field", {
                    staticClass: "parcelas",
                    attrs: {
                      outlined: "",
                      dense: "",
                      label: "Endereço",
                      required: "",
                      readonly: "",
                      "hide-details": "auto"
                    },
                    model: {
                      value: _vm.titulo.ds_endereco_devedor,
                      callback: function($$v) {
                        _vm.$set(_vm.titulo, "ds_endereco_devedor", $$v)
                      },
                      expression: "titulo.ds_endereco_devedor"
                    }
                  })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-0" },
        [
          _c(
            "v-col",
            { staticClass: "pb-3" },
            [
              _c("v-text-field", {
                attrs: {
                  outlined: "",
                  dense: "",
                  value: _vm.titulo.ds_bairro_devedor,
                  label: "Bairro",
                  "hide-details": "auto",
                  readonly: ""
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pb-3" },
            [
              _c("v-text-field", {
                attrs: {
                  outlined: "",
                  dense: "",
                  value: _vm.titulo.nm_cidade_devedor,
                  label: "Cidade",
                  readonly: "",
                  "hide-details": "auto"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pb-3" },
            [
              _c("v-text-field", {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: "#####-###",
                    expression: "'#####-###'"
                  }
                ],
                attrs: {
                  outlined: "",
                  dense: "",
                  value: _vm.titulo.cd_cep_devedor,
                  label: "CEP",
                  "hide-details": "auto",
                  readonly: ""
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pb-3", attrs: { cols: "1" } },
            [
              _c("v-text-field", {
                attrs: {
                  outlined: "",
                  dense: "",
                  value: _vm.titulo.cd_uf_devedor,
                  label: "UF",
                  readonly: "",
                  "hide-details": "auto"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pb-3" },
            [
              _c("v-text-field", {
                attrs: {
                  outlined: "",
                  dense: "",
                  value: _vm.titulo.ds_email_devedor,
                  label: "E-mail do devedor",
                  readonly: "",
                  "hide-details": "auto"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pb-3" },
            [
              _c("v-text-field", {
                attrs: {
                  outlined: "",
                  "hide-details": "auto",
                  dense: "",
                  value: _vm.titulo.nu_telefone_devedor,
                  label: "Telefone do devedor",
                  readonly: ""
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm.habilitaCorrecao
        ? _c(
            "v-dialog",
            {
              attrs: {
                persistent: "",
                transition: "dialog-bottom-transition",
                "max-width": "900px"
              },
              model: {
                value: _vm.habilitaCorrecao,
                callback: function($$v) {
                  _vm.habilitaCorrecao = $$v
                },
                expression: "habilitaCorrecao"
              }
            },
            [
              _c("correcao-titulo", {
                attrs: { detalhe_titulo: _vm.titulo, open: 1 },
                on: { fecharModal: _vm.desabilitaDialogCorrecao }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.feedbackSnackbar
        ? _c("Snackbar", {
            attrs: {
              mostrarSnackbar: _vm.feedbackSnackbar,
              corSnackbar: _vm.feedbackSnackbarColor,
              mensagemSnackbar: _vm.mensagemfeedbackSnackbar
            },
            on: {
              fecharSnackbar: function($event) {
                _vm.feedbackSnackbar = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }