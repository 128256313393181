var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "pa-3 card-historico-emails" },
    [
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("h4", { staticClass: "bold" }, [_vm._v("Historico de E-mails")])
          ]),
          _c(
            "v-col",
            { staticClass: "d-flex justify-end" },
            [
              _vm.listaEnvios.length
                ? _c(
                    "v-btn",
                    {
                      attrs: { loading: _vm.loadingTituloImpressao },
                      on: {
                        click: function($event) {
                          return _vm.historicoImpressao()
                        }
                      }
                    },
                    [
                      _c("v-icon", [_vm._v("mdi-printer")]),
                      _vm._v(" Imprimir ")
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("v-data-table", {
                staticClass: "elevation-1",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.listaEnvios,
                  loading: _vm.loading,
                  "server-items-length": _vm.listaEnvios.length,
                  "loading-text": "Buscando envios...",
                  "item-key": "comarcas",
                  "no-data-text": "Nenhum E-mail Enviado",
                  "footer-props": {
                    itemsPerPageOptions: [20, 100, 200, -1]
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: `item.dt_envio`,
                      fn: function({ item }) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(_vm.formatDate(item.dt_envio)))
                          ])
                        ]
                      }
                    },
                    {
                      key: `item.dt_abertura`,
                      fn: function({ item }) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(_vm.formatDate(item.dt_abertura)))
                          ])
                        ]
                      }
                    },
                    {
                      key: `item.dt_clique`,
                      fn: function({ item }) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(_vm.formatDate(item.dt_clique)))
                          ])
                        ]
                      }
                    },
                    {
                      key: `item.dt_entregue`,
                      fn: function({ item }) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(_vm.formatDate(item.dt_entregue)))
                          ])
                        ]
                      }
                    },
                    {
                      key: `item.cd_status`,
                      fn: function({ item }) {
                        return [
                          item.cd_status == "102"
                            ? _c("span", [_c("p", [_vm._v("Protestado")])])
                            : _vm._e(),
                          item.cd_status == "225"
                            ? _c("span", [_c("p", [_vm._v("Apontado")])])
                            : _vm._e(),
                          item.cd_status == "241"
                            ? _c("span", [
                                _c("p", [_vm._v("Enviado a Cancelamento")])
                              ])
                            : _vm._e(),
                          item.cd_status == "251"
                            ? _c("span", [
                                _c("p", [_vm._v("Enviado a Anuência")])
                              ])
                            : _vm._e(),
                          item.cd_status == "252"
                            ? _c("span", [
                                _c("p", [_vm._v("Anuência Programada")])
                              ])
                            : _vm._e()
                        ]
                      }
                    },
                    {
                      key: `item.st_ativo`,
                      fn: function({ item }) {
                        return [
                          _c(
                            "v-icon",
                            {
                              style: {
                                color: item.st_ativo != "" ? "green" : "red"
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.getBolean(item.st_ativo)))]
                          )
                        ]
                      }
                    },
                    {
                      key: `item.is_admin`,
                      fn: function({ item }) {
                        return [
                          _c(
                            "v-icon",
                            {
                              style: {
                                color: item.is_admin != "" ? "green" : "red"
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.getBolean(item.is_admin)))]
                          )
                        ]
                      }
                    },
                    {
                      key: `item.tracking`,
                      fn: function({ item }) {
                        return [
                          item.tracking == "2"
                            ? _c(
                                "span",
                                { staticClass: "d-flex flex-row" },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "", color: "success" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function({ on, attrs }) {
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: {
                                                          elevation: "0",
                                                          "x-small": "",
                                                          fab: ""
                                                        }
                                                      },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass:
                                                          "ma-2 pa-2 blue--text"
                                                      },
                                                      [_vm._v("mdi-check-all")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          "E-mail visualizado pelo destinatário"
                                        )
                                      ])
                                    ]
                                  ),
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "", color: "success" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function({ on, attrs }) {
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: {
                                                          elevation: "0",
                                                          "x-small": "",
                                                          fab: ""
                                                        }
                                                      },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "ma-2 pa-2"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "mdi-cursor-default-click"
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [
                                      _c("span", [
                                        _vm._v("Essa mensagem recebeu cliques")
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          item.tracking == "1"
                            ? _c(
                                "span",
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "", color: "success" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function({ on, attrs }) {
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: {
                                                          "x-small": "",
                                                          fab: "",
                                                          elevation: "0"
                                                        }
                                                      },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass:
                                                          "ma-2 pa-2 blue--text"
                                                      },
                                                      [_vm._v("mdi-check-all")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          "E-mail visualizado pelo destinatário"
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          item.tracking == "0"
                            ? _c(
                                "span",
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "", color: "success" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function({ on, attrs }) {
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: {
                                                          "x-small": "",
                                                          fab: "",
                                                          elevation: "0"
                                                        }
                                                      },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass:
                                                          "ma-2 pa-2 grey--text"
                                                      },
                                                      [_vm._v("mdi-check")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          "E-mail Entregue ao destinatário"
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          item.tracking == null
                            ? _c(
                                "span",
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "", color: "grey" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function({ on, attrs }) {
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: {
                                                          elevation: "0",
                                                          "x-small": "",
                                                          fab: ""
                                                        }
                                                      },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "ma-2 pa-2"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "mdi-dots-horizontal"
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [_c("span", [_vm._v("E-mail enviado")])]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      }
                    },
                    {
                      key: `item.action`,
                      fn: function({ item }) {
                        return [
                          item.ds_conteudo == undefined
                            ? _c(
                                "span",
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "", color: "grey" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function({ on, attrs }) {
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        style: {
                                                          color: "gray"
                                                        },
                                                        attrs: {
                                                          elevation: "0",
                                                          "x-small": "",
                                                          fab: ""
                                                        }
                                                      },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "ma-2 pa-2"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "mdi-eye-outline"
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          " Pré visualização não disponivel "
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            : _c(
                                "span",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      style: { color: "black" },
                                      attrs: {
                                        elevation: "0",
                                        "x-small": "",
                                        fab: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.exibirDetalhe(item)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        { staticClass: "ma-2 pa-2" },
                                        [_vm._v("mdi-eye-outline")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            transition: "dialog-bottom-transition",
            "max-width": "1000px"
          },
          model: {
            value: _vm.showTemplateDialog,
            callback: function($$v) {
              _vm.showTemplateDialog = $$v
            },
            expression: "showTemplateDialog"
          }
        },
        [
          _vm.selectedEmail
            ? _c(_vm.renderTemplate(), {
                tag: "component",
                attrs: {
                  usuarioPartner: _vm.usuarioPartner,
                  nomeDevedor: _vm.titulo.nm_devedor,
                  nomeExibicaoApresentante: _vm.NomeExibicaoApresentante
                },
                on: {
                  fecharModal: function($event) {
                    _vm.showTemplateDialog = $event
                  }
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            transition: "dialog-bottom-transition",
            "max-width": "1024px"
          },
          model: {
            value: _vm.showEmailDialog,
            callback: function($$v) {
              _vm.showEmailDialog = $$v
            },
            expression: "showEmailDialog"
          }
        },
        [_c("span", { domProps: { innerHTML: _vm._s(_vm.emailContent) } })]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }