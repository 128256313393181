var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "pa-3" },
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "6" } }, [
            _c("h4", { staticClass: "bold" }, [_vm._v("Sacador")])
          ]),
          _c("v-col", [_c("h4", { staticClass: "bold" }, [_vm._v("Cedente")])]),
          _c("v-col", { staticClass: "d-flex justify-end" })
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "py-1", attrs: { cols: "4" } },
            [
              _c("v-text-field", {
                attrs: {
                  dense: "",
                  outlined: "",
                  value: _vm.titulo.nm_sacador,
                  label: "Nome do sacador",
                  readonly: "",
                  "hide-details": "auto"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "py-1" },
            [
              _c("v-text-field", {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: ["XX.XXX.XXX/XXXX-##", "###.###.###-##"],
                    expression: "['XX.XXX.XXX/XXXX-##', '###.###.###-##']"
                  }
                ],
                attrs: {
                  outlined: "",
                  dense: "",
                  value: _vm.titulo.cnpj_sacador,
                  label: "CNPJ",
                  readonly: "",
                  "hide-details": "auto"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "py-1", attrs: { cols: "6" } },
            [
              _c("v-text-field", {
                attrs: {
                  outlined: "",
                  dense: "",
                  value: _vm.titulo.nm_cedente_favorecido,
                  label: "Nome do cedente favorecido",
                  readonly: "",
                  "hide-details": "auto"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-0" },
        [
          _c("v-col", { attrs: { cols: "6" } }, [
            _c("h4", { staticClass: "bold" }, [_vm._v("Apresentante")])
          ])
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "pb-3" },
        [
          _c(
            "v-col",
            { staticClass: "py-1", attrs: { cols: "4" } },
            [
              _c("v-text-field", {
                attrs: {
                  outlined: "",
                  dense: "",
                  value: _vm.titulo.nm_razao_social,
                  label: "Razão social",
                  readonly: "",
                  "hide-details": "auto"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "py-1", attrs: { cols: "2" } },
            [
              _c("v-text-field", {
                attrs: {
                  outlined: "",
                  dense: "",
                  value: _vm.titulo.cd_apresentante,
                  label: "Código",
                  "hide-details": "auto",
                  readonly: ""
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }