var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "pa-3" },
    [
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("h4", { staticClass: "bold" }, [
              _vm._v("Lista de arquivos Remessa/Confirmação")
            ])
          ])
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-list",
                [
                  _c(
                    "v-list-item-group",
                    _vm._l(_vm.arquivos, function(item, index) {
                      return _c(
                        "v-list-item",
                        { key: index },
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v(_vm._s(item.nm_arquivo_cra))
                              ]),
                              _c("v-list-item-subtitle", [
                                _vm._v(
                                  "Status: " +
                                    _vm._s(item.cd_status) +
                                    " | Comarca: " +
                                    _vm._s(item.cd_ibge_envio_comarca) +
                                    " | UF: " +
                                    _vm._s(item.cd_uf)
                                )
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-action",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { icon: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.downloadFile(item)
                                    }
                                  }
                                },
                                [_c("v-icon", [_vm._v("mdi-download")])],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }