var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "pa-3" },
    [
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("h4", { staticClass: "bold" }, [_vm._v("Apontamento")])
          ]),
          _c(
            "v-col",
            { staticClass: "d-flex justify-end" },
            [
              _c("select-actions", {
                attrs: {
                  actions: [
                    {
                      name: "Editar Dados",
                      method: "editarApontamento",
                      disabled: _vm.checkPermission
                    }
                  ]
                },
                on: { executeAction: _vm.callMethod }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm.titulo.apontamento_nu_protocolo
        ? _c(
            "div",
            { staticClass: "mt-5" },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "pb-3", attrs: { cols: "4" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          dense: "",
                          outlined: "",
                          value: _vm.titulo.apontamento_nu_protocolo,
                          label: "Protocolo do cartório",
                          readonly: "",
                          "hide-details": "auto"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "pb-3" },
                    [
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          dense: "",
                          value: _vm
                            .moment(_vm.titulo.apontamento_dt_protocolo)
                            .format("DD/MM/YYYY"),
                          label: "Data de apontamento",
                          readonly: "",
                          "hide-details": "auto"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "pb-3" },
                    [
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          dense: "",
                          value:
                            _vm.titulo.apontamento_cd_municipio +
                            " - " +
                            _vm.titulo.nm_municipio_apontamento,
                          label: "Municipio de apontamento",
                          "hide-details": "auto",
                          readonly: ""
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mt-0" },
                [
                  _c(
                    "v-col",
                    { staticClass: "pb-3" },
                    [
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          dense: "",
                          value: _vm.titulo.nm_cartorio_apontamento,
                          label: "Cartório",
                          readonly: "",
                          "hide-details": "auto"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mt-0" },
                [
                  _c(
                    "v-col",
                    { staticClass: "pb-3" },
                    [
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          dense: "",
                          value: _vm.titulo.ds_endereco_sacador,
                          label: "Endereço do cartório",
                          "hide-details": "auto",
                          readonly: ""
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mt-0" },
                [
                  _c(
                    "v-col",
                    { staticClass: "pb-3" },
                    [
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          dense: "",
                          value: _vm.cartorioInfos.bairro,
                          label: "Bairro",
                          "hide-details": "auto",
                          readonly: ""
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "pb-3" },
                    [
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          dense: "",
                          value: _vm.cartorioInfos.nm_cidade,
                          label: "Cidade",
                          "hide-details": "auto",
                          readonly: ""
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "pb-3", attrs: { cols: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          dense: "",
                          value: _vm.cartorioInfos.cd_uf,
                          label: "UF",
                          "hide-details": "auto",
                          readonly: ""
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mt-0" },
                [
                  _c(
                    "v-col",
                    { staticClass: "pb-3" },
                    [
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          dense: "",
                          value: _vm.titulo.email_cartorio,
                          label: "E-mail do cartório",
                          readonly: "",
                          "hide-details": "auto"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "pb-3" },
                    [
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          dense: "",
                          value: _vm.titulo.telefone_cartorio,
                          label: "Telefone do cartório",
                          "hide-details": "auto",
                          readonly: ""
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "feedback" },
            [
              _c(
                "v-alert",
                {
                  attrs: {
                    prominent: "",
                    icon: "mdi-file-document",
                    shaped: "",
                    color: "#f5f5f5"
                  }
                },
                [
                  _vm._v(
                    " Este título ainda não foi protocolado/apontado em cartório "
                  )
                ]
              )
            ],
            1
          ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "800px" },
          model: {
            value: _vm.modalApontamento,
            callback: function($$v) {
              _vm.modalApontamento = $$v
            },
            expression: "modalApontamento"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "pa-3" },
            [
              _c(
                "v-row",
                [
                  _c("v-col", [
                    _c("h4", { staticClass: "bold" }, [_vm._v("Apontamento")])
                  ])
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-card",
                        { staticClass: "pa-2" },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { lg: "6" } },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "ma-2",
                                      attrs: { small: "" }
                                    },
                                    [_vm._v(" mdi-home-city ")]
                                  ),
                                  _vm._v(" Número do Protocolo "),
                                  _c("br"),
                                  _c("v-text-field", {
                                    attrs: {
                                      outlined: "",
                                      dense: "",
                                      label: "Protocolo do Cartório",
                                      required: "",
                                      maxlength: "10",
                                      onkeypress: _vm.permitirLetrasNumeros(
                                        false
                                      )
                                    },
                                    model: {
                                      value: _vm.novoProtocolo,
                                      callback: function($$v) {
                                        _vm.novoProtocolo = $$v
                                      },
                                      expression: "novoProtocolo"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { lg: "6" } },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "ma-2",
                                      attrs: { small: "" }
                                    },
                                    [_vm._v(" mdi-home-city ")]
                                  ),
                                  _vm._v(" Data do Apontamento "),
                                  _c("br"),
                                  _c(
                                    "v-menu",
                                    {
                                      attrs: {
                                        "close-on-content-click": false,
                                        transition: "scale-transition",
                                        "min-width": "auto"
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function({ on, attrs }) {
                                            return [
                                              _c(
                                                "v-text-field",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      directives: [
                                                        {
                                                          name: "maska",
                                                          rawName: "v-maska",
                                                          value: "##/##/####",
                                                          expression:
                                                            "'##/##/####'"
                                                        }
                                                      ],
                                                      staticClass: "parcelas",
                                                      attrs: {
                                                        outlined: "",
                                                        dense: "",
                                                        label:
                                                          "Clique para selecionar",
                                                        required: "",
                                                        locale: "pt-br",
                                                        "prepend-icon":
                                                          "mdi-calendar",
                                                        hint:
                                                          "Formato: dd/mm/AAAA"
                                                      },
                                                      on: {
                                                        keyup: function(
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          return _vm.validarDataDigitada(
                                                            true,
                                                            $event
                                                          )
                                                        },
                                                        blur: function($event) {
                                                          return _vm.validarDataDigitada()
                                                        }
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.dataDigitavel,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.dataDigitavel = $$v
                                                        },
                                                        expression:
                                                          "dataDigitavel"
                                                      }
                                                    },
                                                    "v-text-field",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                )
                                              )
                                            ]
                                          }
                                        }
                                      ]),
                                      model: {
                                        value: _vm.menu2,
                                        callback: function($$v) {
                                          _vm.menu2 = $$v
                                        },
                                        expression: "menu2"
                                      }
                                    },
                                    [
                                      _c("v-date-picker", {
                                        attrs: {
                                          locale: "pt-br",
                                          color: "primary"
                                        },
                                        on: {
                                          input: function($event) {
                                            return _vm.selecionarData(_vm.data)
                                          }
                                        },
                                        model: {
                                          value: _vm.data,
                                          callback: function($$v) {
                                            _vm.data = $$v
                                          },
                                          expression: "data"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "py-0" },
                    [
                      _c(
                        "v-card",
                        { staticClass: "pa-2" },
                        [
                          _c(
                            "v-card-text",
                            { staticClass: "ma-2 pa-2" },
                            [
                              _c(
                                "v-icon",
                                { staticClass: "ma-2", attrs: { small: "" } },
                                [_vm._v(" mdi-home-city ")]
                              ),
                              _vm._v(" Selecione o novo Município ")
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { lg: "1" } },
                                [
                                  _c("v-progress-circular", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.loadingMunicipios,
                                        expression: "loadingMunicipios"
                                      }
                                    ],
                                    staticClass: "ml-5",
                                    attrs: {
                                      color: "primary",
                                      small: "",
                                      indeterminate: ""
                                    }
                                  }),
                                  _c(
                                    "v-icon",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.iconLateral,
                                          expression: "iconLateral"
                                        }
                                      ],
                                      staticClass: "ml-5",
                                      attrs: { large: "" }
                                    },
                                    [_vm._v("mdi-chevron-right")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { lg: "8" } },
                                [
                                  _c("v-autocomplete", {
                                    attrs: {
                                      items: _vm.itensMunicipios.municipios,
                                      dense: "",
                                      loading: _vm.loadingMunicipios,
                                      disabled: _vm.loadingMunicipios,
                                      "item-text": "nm_estado_municipio",
                                      "item-value": "cd_municipio_completo",
                                      outlined: "",
                                      label: "Selecione o Município"
                                    },
                                    on: { input: _vm.buscaCartorio },
                                    model: {
                                      value: _vm.valueMunicipios,
                                      callback: function($$v) {
                                        _vm.valueMunicipios = $$v
                                      },
                                      expression: "valueMunicipios"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { lg: "3" } },
                                [
                                  _c("v-autocomplete", {
                                    attrs: {
                                      items: _vm.itensMunicipios.municipios,
                                      dense: "",
                                      outlined: "",
                                      "item-text": "cd_ibge_comarca",
                                      "item-value": "cd_municipio_completo",
                                      label: "Comarca"
                                    },
                                    on: { input: _vm.buscaCartorio },
                                    model: {
                                      value: _vm.valueMunicipios,
                                      callback: function($$v) {
                                        _vm.valueMunicipios = $$v
                                      },
                                      expression: "valueMunicipios"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "pt-3" },
                    [
                      _c(
                        "v-card",
                        { staticClass: "pa-2" },
                        [
                          _c(
                            "v-card-text",
                            { staticClass: "ma-2 pa-2" },
                            [
                              _c(
                                "v-icon",
                                { staticClass: "ma-2", attrs: { small: "" } },
                                [_vm._v("mdi-home-city")]
                              ),
                              _vm._v(" Selecione o novo Cartório ")
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { lg: "1" } },
                                [
                                  _c("v-progress-circular", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.loadingCartorios,
                                        expression: "loadingCartorios"
                                      }
                                    ],
                                    staticClass: "ml-5",
                                    attrs: {
                                      color: "primary",
                                      indeterminate: ""
                                    }
                                  }),
                                  _c(
                                    "v-icon",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.iconLateralCartorios,
                                          expression: "iconLateralCartorios"
                                        }
                                      ],
                                      staticClass: "ml-5",
                                      attrs: { large: "" }
                                    },
                                    [_vm._v("mdi-chevron-right")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { lg: "8" } },
                                [
                                  _c("v-autocomplete", {
                                    attrs: {
                                      items: _vm.itemsCartorios,
                                      dense: "",
                                      "no-data-text":
                                        "Nenhum cartório encontrado para esse município",
                                      "item-text": "nm_cartorio",
                                      "item-value": "nu_cartorio",
                                      outlined: "",
                                      label: "Selecione o Cartório"
                                    },
                                    model: {
                                      value: _vm.valueCartorios,
                                      callback: function($$v) {
                                        _vm.valueCartorios = $$v
                                      },
                                      expression: "valueCartorios"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { lg: "3" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.itemsCartorios,
                                      dense: "",
                                      outlined: "",
                                      "item-text": "nu_cartorio",
                                      "item-value": "nu_cartorio",
                                      label: "Número do Cartório"
                                    },
                                    model: {
                                      value: _vm.valueCartorios,
                                      callback: function($$v) {
                                        _vm.valueCartorios = $$v
                                      },
                                      expression: "valueCartorios"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { align: "right" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-3",
                          attrs: { color: "success" },
                          on: { click: _vm.salvarDadosComarca }
                        },
                        [_vm._v(" Salvar ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          on: {
                            click: function($event) {
                              _vm.modalApontamento = false
                            }
                          }
                        },
                        [_vm._v(" Cancelar ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.snackbarDialog
        ? _c("Snackbar", {
            attrs: {
              mostrarSnackbar: _vm.snackbarDialog,
              corSnackbar: _vm.snackbarColor,
              mensagemSnackbar: _vm.mensagemSnackBar
            },
            on: {
              fecharSnackbar: function($event) {
                _vm.snackbarDialog = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }