var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "pa-3" },
    [
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("h4", { staticClass: "bold" }, [_vm._v("Comentarios do Título")])
          ])
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              !_vm.comentarios.length
                ? _c("span", [_vm._v("Não há comentarios neste título")])
                : _c(
                    "v-list",
                    [
                      _c(
                        "v-list-item-group",
                        _vm._l(_vm.comentarios, function(comentario, index) {
                          return _c(
                            "v-list-item",
                            { key: index, staticClass: "bg-comentario" },
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _c("strong", [
                                      _vm._v(
                                        " " +
                                          _vm._s(comentario.email_user) +
                                          "(ID: " +
                                          _vm._s(comentario.id_user) +
                                          "/" +
                                          _vm._s(comentario.name_user) +
                                          ") - Criado em " +
                                          _vm._s(
                                            _vm.FormateData(
                                              comentario.dt_criacao
                                            )
                                          ) +
                                          ": "
                                      ),
                                      _c("i", { staticClass: "text-gray" }, [
                                        _vm._v(_vm._s(comentario.comentario))
                                      ])
                                    ])
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
            ],
            1
          )
        ],
        1
      ),
      _vm.addComentario
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("v-textarea", {
                    attrs: {
                      label: "Novo comentario",
                      outlined: "",
                      placeholder: "Escreva aqui seu comentario"
                    },
                    model: {
                      value: _vm.novoComentario,
                      callback: function($$v) {
                        _vm.novoComentario = $$v
                      },
                      expression: "novoComentario"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.addComentario
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "d-flex justify-end" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "red", dark: "" },
                      on: {
                        click: function($event) {
                          _vm.addComentario = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-close")]), _vm._v(" Cancelar ")],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-3",
                      attrs: { loading: _vm.loadingSave, color: "success" },
                      on: {
                        click: function($event) {
                          return _vm.criarComentario()
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-check")]), _vm._v(" Salvar ")],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      !_vm.addComentario
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "d-flex justify-end" },
                [
                  _c(
                    "v-btn",
                    {
                      on: {
                        click: function($event) {
                          _vm.addComentario = true
                        }
                      }
                    },
                    [
                      _c("v-icon", [_vm._v("mdi-plus")]),
                      _vm._v(" Adicionar comentario ")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }